import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import guillaumeBackground from "../../images/guillaume_lafond_images/Biographie.jpg";


const Container = styled.div`
  ${tw`relative bg-center bg-no-repeat bg-cover h-screen `}
  background-image: url("${guillaumeBackground}");
  padding-top: 200px; /* Adjust if necessary to avoid overlap with the header */

  /* Default background properties */
  background-size: cover;
  background-position: center center;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);

  /* Adjusting height */
  height: 100vh; /* Sets the height to be at least the full viewport height */
  min-height: 500px; /* Sets a minimum height for the image */

  /* Media Query for screens smaller than 500px */
  @media (max-width: 2000px) {
    background-size: cover; /* Continue using cover for a zoom effect */
    background-position: 47%; /* Adjusts to focus on 1/3 of the image */
  }
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0 bg-black opacity-25`;

const HeroContainer = tw.div`z-20 relative mx-auto h-full flex flex-col`;

const BioTextContainer = styled.div`
  ${tw`p-8 md:p-12 lg:p-16 bg-[rgba(175,155,135,0.6)] rounded-lg shadow-xl`}
  position: absolute; 
  top: 27%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px; 
  width: 90%;
  z-index: 30;
  text-align: justify;
  overflow-y: auto;
  max-height: 80%;
  color: black;
  font-size: 1.125rem; // Start with a slightly bigger font size for the base

  @media (max-width: 1024px) {
    font-size: 1.3rem; // Adjust font size for tablets
  }

  @media (max-width: 768px) {
    font-size: 1.2rem; // Adjust font size for small tablets
  }

  @media (max-width: 640px) {
    font-size: 1rem; // Adjust font size for large phones
  }

  @media (max-width: 480px) {
    font-size: 0.85rem; // Adjust font size for small phones
    padding: 4px; // Adjust padding for small phones
  }
`;


export default () => {
    return (
      <Container>
        <OpacityOverlay />
        <HeroContainer>
          <BioTextContainer>
            <p style={{ textIndent: '20px' }}>
              Jouant de la guitare depuis ses 10 ans, c’est à l’adolescence que Guillaume Lafond 
              décide qu’il allait gagner sa vie dans ce domaine. Après avoir fait son Cégep en 
              musique et avoir passé 3 ans sur les bateaux de croisière, il commence à performer 
              en solo. Il accumule les spectacles dans les restaurants et bars de la Montérégie 
              jusqu’à ce qu’il s’inscrive à l’édition 2021 de Star Académie. Se démarquant par sa 
              chaleureuse présence sur scène et son style country authentique, Guillaume est l’un 
              des favoris du public lors de cette édition. Cette nouvelle expérience propulse sa 
              carrière vers de nouveaux horizons. Sous l’étiquette Musicor, Guillaume Lafond lance 
              son premier album À destination en octobre 2022, composé de dix chansons dont neuf 
              compositions originales inspirées de ses histoires personnelles. Depuis, le jeune 
              artiste enchaîne les spectacles dans plusieurs salles iconiques et festivals à travers 
              le Québec, en plus d’assurer les premières parties de la tournée de Guylaine Tanguay. 
              Parallèlement à sa carrière solo, Guillaume fait également partie de la Grande Ourse, 
              un projet collectif rassemblant sept artistes country émergents dont l’album de 10 
              compositions originales est sorti en septembre 2023.
            </p>
            <p style={{ textIndent: '20px' }}>
              Artiste grandement chéri du public québécois, Guillaume Lafond a récolté pas moins 
              de cinq nominations au Gala Country 2023 pour les catégories Album Country 
              contemporain, Auteur compositeur, Chanson, Découverte et Interprète masculin de 
              l’année. Il a également été nommé pour l’Album Country de l’année au Gala de l’ADISQ. 
              En pleine création de ses nouvelles chansons pour son prochain album, Guillaume 
              poursuivra la tournée de son spectacle à travers tout le Québec en 2024.
            </p>
          </BioTextContainer>
        </HeroContainer>
      </Container>
    );
  };
  

